import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MiniHeader from './MiniHeader';
import { sliceText } from './Functions';
import * as styles from './Ueberuns.module.css';

function Ueberuns() {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                sectionhead {
                  ueberuns
                }
                ueberuns {
                  lang
                  name
                  url
                }
            }
        }
    `)

    const persons = data.configJson.ueberuns;
    const imgPath = "/images/";
    
    return (
        <div>
            <MiniHeader headline={data.configJson.sectionhead.ueberuns}></MiniHeader>
            <div className={styles.aboutContainer}>
                
                { persons.map((person) => {
                    let data = sliceText(person.lang);
                    return <div key={person.name} className={styles.aboutWrapper}>
                                <img className={styles.aboutImage} src={`${imgPath}${person.url}`} alt={person.name}/>    
                                <div>
                                    <h4 className={styles.aboutName}>{person.name}</h4>
                                    {data.map((elem) =>{
                                        return <p key={elem} className={styles.aboutText}>{elem}</p>
                                    })}
                                </div>
                            </div>
                })}

            <p onClick={() => window.history.back()} className="defaultBtn">zurück</p>      
            </div>
        </div>
    )
}

export default Ueberuns