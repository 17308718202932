import React from 'react';

export function sliceText(splitBody) {
    let paragraphArray = [];
    
    if (splitBody.indexOf("\n", 0) !== -1) {
        while (splitBody.indexOf("\n", 0) !== -1) {
            paragraphArray.push(splitBody.slice(0, (splitBody.indexOf("\n", 0) )))
            splitBody = splitBody.slice((splitBody.indexOf("\n", 0) +1 ))
        }
        paragraphArray.push(splitBody)
    } else {
        paragraphArray.push(splitBody)
    }
    return paragraphArray
}

export default function Functions() {
    return (null)
}
