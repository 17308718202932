import React from "react";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import Info from "../components/Info";
import Ueberuns from "../components/Ueberuns_";
import './index.css';



const aboutPage = () => {
  return (
    <>    
      <Helmet>
          <title>Über uns</title>
          <meta name='description' content="Das Praxis Team stellt sich vor: Tim und Laura Winthagen"/>
          <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
          <meta property='og:title' content="Wer wir sind"/>
          <meta property='og:type' content='Website'/>
          <meta property='og:description' content="Das Praxis Team stellt sich vor: Tim und Laura Winthagen"/>
          <meta property='og:image' content=''/>
          <meta property='og:locale' content='de'/>
            <meta property='og:url' content={`https://www.physiotherapie-winthagen.de/ueberund`}/>
          <link rel="canonical" href={`https://www.physiotherapie-winthagen.de/ueberuns`}/>
      </Helmet>  
      <Layout>
          <Ueberuns></Ueberuns>
          <Info />
      </Layout>
    </>  
  )
}

export default aboutPage